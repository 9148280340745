import { v4 as uuidv4 } from 'uuid';

function generateUUIDv4() {
  return uuidv4();
}

function scrollToElementWithOffset(element: HTMLElement, offset = 0) {
  const elementTop = element.getBoundingClientRect().top;
  const scrollTop = elementTop + offset;

  window.scrollTo({
    top: scrollTop,
    behavior: "smooth",
  });
}

function generateRandomPassword() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!_$?';
  const length = 12;
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}


const helpers = {
  generateUUIDv4,
  scrollToElementWithOffset,
  generateRandomPassword
};

export default helpers;
